<template>
	<v-layout v-if="totalPages > 1" shrink align-center>
		<v-flex v-if="$vuetify.breakpoint.smAndUp" text-no-wrap ml-2 v-text="$t('documents.preview.pages')" />
		<v-tooltip v-bind="$attrs" z-index="300">
			<template v-slot:activator="{ on }">
				<div v-on="on">
					<v-btn :small="smallBtn" icon :disabled="page <= 1" v-on="on" @click="previous()">
						<v-icon>chevron_left</v-icon>
					</v-btn>
				</div>
			</template>
			<span v-t="'documents.preview.previous_page'" />
		</v-tooltip>
		<v-flex v-text="page + '/' + totalPages" />
		<v-tooltip v-bind="$attrs" z-index="300">
			<template v-slot:activator="{ on }">
				<div v-on="on">
					<v-btn :small="smallBtn" :disabled="page === totalPages" icon v-on="on" @click="next()">
						<v-icon>chevron_right</v-icon>
					</v-btn>
				</div>
			</template>
			<span v-t="'documents.preview.next_page'" />
		</v-tooltip>
	</v-layout>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import SmallButton from "@/mixins/SmallButton";

export default {
	name: 'DocumentPagesNavigation',
	mixins: [
			DocumentVersionsModuleGuard,
			SmallButton,
	],
	props: {
		displayThumbnails: {
			type: Boolean,
			required: true
		},
		page: {
			type: Number,
			required: true
		},
		totalPages: {
			type: Number,
			required: true
		}
	},
	computed: {
		showThumbnails: {
			get: function () {
				return this.displayThumbnails
			},
			set: function (value) {
				this.$emit('display-thumbnails-changed', value)
			}
		},
	},
	methods: {
		getKeyboardEventsActionsMapping: function () {
			return [
				{
					actions: [
						{
							key: this.keyboard.arrow.up,
							action: this.previous
						},
						{
							key: this.keyboard.arrow.down,
							action: this.next
						}
					]
				}
			]
		},
		next: function () {
			this.eventBus.emit(this.events.preview.NEXT_PAGE)
		},
		previous: function () {
			this.eventBus.emit(this.events.preview.PREVIOUS_PAGE)
		}
	}
}
</script>
